import * as React from "react";
import "./job-offer.scss";
import { OfferListItems } from "./OfferListItems/offer-list-items";
import { OfferRequirements, OfferRequirementsType } from "./OfferRequirements/offer-requirements";

export interface JobOfferType {
    title: string;
    description: string;
    ourOffer: string[];
    ourRequirements: OfferRequirementsType[];
    reasonsToJoin: string[];
    responsibleFor: string[];
}

const JobOffer = (props: { offer: JobOfferType }) => {
    return (
        <div className="job-offer">
            <details>
                <summary>
                    {props.offer.title}
                    <div className="job-offer_line"></div>
                </summary>
                <strong>About the Position:</strong>
                <p>{props.offer.description}</p>
                <div>
                    <span>As a .NET Developer, you will be responsible for:</span>
                    <OfferListItems items={props.offer.responsibleFor} />
                </div>
                <div>
                    <strong>Requirements:</strong>
                    <OfferRequirements requirements={props.offer.ourRequirements} />
                </div>
                <div>
                    <strong>Offer:</strong>
                    <OfferListItems items={props.offer.ourOffer} />
                </div>
                <div>
                    <strong>Why you should join us:</strong>
                    <OfferListItems items={props.offer.reasonsToJoin} />
                </div>
                <p>
                    <strong>Markana</strong> is a team full of passion and determination, specializing in creating and maintaining business applications. We value excellent collaboration and an individual approach to each project. We focus on people and their development, offering a friendly work atmosphere and the opportunity to work on ambitious projects. We ensure that every team member feels appreciated and has an impact on shaping the company.
                </p>
            </details>
        </div>
    )
}

export default JobOffer;