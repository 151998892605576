import React from "react";
import { OfferListItems } from "../OfferListItems/offer-list-items";

export interface OfferRequirementsType {
  title: string;
  nestedRequirements?: string[];
}

export const OfferRequirements = (props: { requirements: OfferRequirementsType[] }) => {
  return (
    <ul>
      {props.requirements.map((requirement: OfferRequirementsType, i: number) => {
        return (
          <li key={i}>
            <span>{requirement.title}</span>
            <OfferListItems items={requirement.nestedRequirements} />
          </li>
        )
      })}
    </ul>
  )
}