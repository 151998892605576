import * as React from "react"
import type {HeadFC} from "gatsby"
import {useTranslation} from "react-i18next";
import {SEO} from "../components/Seo/seo";
import {graphql} from "gatsby";
import SubPageTitle from "../components/SubPageTitle/sub-page-title";
import {NegativeSpace} from "../components/NegativeSpace/negative-space";
import CareerSection from "../components/CareerSection/career-section";

const CareerPage = () => {
    const {t} = useTranslation()
    return (
        <main>
            <SubPageTitle title={t("career.title")} subtitle={t("career.subtitle")}/>
            <NegativeSpace/>
            <CareerSection/>
        </main>
    );
}

export default CareerPage;

export const Head: HeadFC = () => <SEO/>

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;