import * as React from "react";
import "./career-section.scss";
import {useTranslation} from "react-i18next";
import Section from "../Section/section";
import {Linolade} from "../Linolade/linolade";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import Container from "../Container/container";
import JobOffer, {JobOfferType} from "./JobOffer/job-offer";
import {Link} from "gatsby-plugin-react-i18next";
import Space from "../Space/space";


const CareerSection = () => {
    const {t} = useTranslation();

    const jobOffers: JobOfferType[] = [{
        title: '.NET Developer',
        description: 'Join our team and work on innovative projects for clients in Poland and abroad.',
        responsibleFor: [
            'Developing and maintaining web applications based on .NET technology',
            'Working in SCRUM teams',
            'Collaborating with clients and project teams'
        ],
        ourOffer: [
            'Compensation: from 110 PLN/h net',
            'Contract type: B2B'
        ],
        ourRequirements: [
            {
                title: 'Minimum 3 years of commercial experience as a .NET Developer'
            },
            {
                title: 'Excellent knowledge of C# language'
            },
            {
                title: 'Familiarity with:',
                nestedRequirements: [
                    'Entity Framework Core and T-SQL',
                    'ASP.NET WebAPI',
                    'CQRS and Mediator patterns',
                    'Domain Driven Design principles',
                    'Cloud solutions: AWS/Azure/GCP'
                ]
            },
            {
                title: 'Ability to produce clean code following SOLID principles'
            },
            {
                title: 'Knowledge of JavaScript or TypeScript is a plus'
            },
            {
                title: 'Fluent in Polish and English'
            },
        ],
        reasonsToJoin: [
            'We provide opportunities for professional development and long-term cooperation',
            'We offer work on inspiring projects in various industries',
            'We guarantee a friendly work environment and an innovative approach to projects'
        ]
    },
        {
            title: 'Full Stack Developer (NodeJS & Angular)',
            description: 'Join our team and work on innovative projects for clients in Poland and abroad.',
            responsibleFor: [
                'Developing and maintaining web applications based on NodeJS and Angular technologies',
                'Working in SCRUM teams',
                'Collaborating with clients and project teams'
            ],
            ourOffer: [
                'Compensation: from 100 PLN/h net',
                'Contract type: B2B'
            ],
            ourRequirements: [
                {
                    title: 'Minimum 3 years of commercial experience as a FullStack Developer'
                },
                {
                    title: 'Very good knowledge of NodeJS and Angular technologies.'
                },
                {
                    title: 'Familiarity with:',
                    nestedRequirements: [
                        'AWS (Amazon Web Services)',
                        'Docker',
                        'SQL databases',
                        'Knowledge how to project modern APIs',
                        'CQRS pattern knowledge'
                    ]
                },
                {
                    title: 'Ability to produce clean code following SOLID principles'
                },
                {
                    title: 'Knowledge of Stripe or Dynamics365 is a plus'
                },
                {
                    title: 'Fluent in Polish and English (used daily)'
                },
            ],
            reasonsToJoin: [
                'We provide opportunities for professional development and long-term cooperation',
                'We offer work on inspiring projects in various industries',
                'We guarantee a friendly work environment and an innovative approach to projects'
            ]
        },
    ]

    return (
        <Section sectionId={"career"}>
            <Linolade/>
            <SectionHeader text={t('career.joinUs.title')} highlightedText={t('career.joinUs.highlightedText')}/>
            <Container>
                <p>{t('career.joinUs.description')}</p>
                <Space/>
                {jobOffers.map((offer: any, i: number) =>
                    <JobOffer key={i} offer={offer}/>
                )}
                <Space/>
                <p>You can apply by sending your CV on:</p>
                <Link className="career-section_link" to={"mailto:career@markana.it"}>career@markana.it</Link>
            </Container>
        </Section>
    );
}

export default CareerSection;